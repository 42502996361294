import './spinerComponent.scss';

function SpinerComponent() {
	return (
		<div className="lds-default">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}
export default SpinerComponent;
